import { Status } from './status';
import { Entity } from './entity';

export class WithStatusEntity extends Entity {

        public status: Status;
        public getItemClassByProperty(propertyName: string): string {
                const classNames: string[] = [];
                if (propertyName === 'status' && this.status) {
                        classNames.push('item-status', 'item');
                        switch (this.status.originalName) {
                                case 'UNPROCESSED':
                                case 'DRAFT':
                                        classNames.push('status-unprocessed');
                                        break;
                                case 'QUOTE':
                                case 'QUOTED':
                                case 'PENDING':
                                case 'WAITING_FOR_REVIEW':
                                case 'WAITING_FOR_ASSIGNMENT':
                                        classNames.push('status-quote');
                                        break;
                                case 'PROFORMA_INVOICE':
                                        classNames.push('status-proforma');
                                        break;
                                case 'DISPATCHED':
                                        classNames.push('status-dispatched');
                                        break;
                                case 'CANCELED':
                                case 'CANCELLED':
                                        classNames.push('status-canceled');
                                        break;
                                case 'COMPLETED':
                                case 'PUBLISHED':
                                        classNames.push('status-completed');
                                        break;
                                case 'PLACED':
                                case 'ASSIGNED':
                                        classNames.push('status-placed');
                                        break;
                                case 'PROCESSING':
                                        classNames.push('status-processing');
                                        break;
                                case 'RETURNED':
                                        classNames.push('status-returned');
                                        break;
                                case 'COMPLAINT':
                                        classNames.push('status-complaint');
                                        break;
                                case 'AWAITING_REDISPATCH':
                                        classNames.push('status-awaiting-redispatch');
                                        break;
                                case 'NEW':
                                        classNames.push('status-new');
                                        break;
                                case 'CONFIRMED':
                                        classNames.push('status-confirmed');
                                        break;
                                case 'IN_PROCESS':
                                        classNames.push('status-in-process');
                                        break;
                                case 'CONVERTED':
                                        classNames.push('status-converted');
                                        break;
                                case 'PICKED':
                                        classNames.push('status-picked');
                                        break;
                                case 'PACKED':
                                        classNames.push('status-packed');
                                        break;
                        }
                }
                return classNames.join(' ');
        }
}
